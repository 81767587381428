<template>
  <div class="home">
<h2>Main</h2>
<button @click="postGroupUser()">グループ登録</button>
    <ul>
      <li>page_id（リンク用）</li>
      <li>title（ページタイトル）</li>
      <li>create_date（作成日時）</li>
      <li>release_date（作成日時）</li>
      <li>create_user（投稿者）</li>
      <li>last_edit_date(最終変更日)</li>
      <li>last_edit_user（最終編集者）</li>
      <li>approval（記事の承認状況）</li>
      <li>delete（個々削除）</li>
      <li>multiselect_delete（複数削除）</li>
      <li>show（表示）</li>
      <li>edit（編集）</li>
    </ul>
  </div>
</template>

<script>
import user from "@/apis/User";
import "@/assets/front_component/js/functions.js";
import $ from 'jquery';
export default {
  name: "Home",
  data(){
    return {
      group_list:[],
      user_list:[]
    }
  },
  components: {},
  created() {
	this.getUser();
	// this.getGroupList();
	// this.postGroupUser();
  console.log(this.$User);
	
  },
  methods: {
    
    putuser() {
      var post_json = {
            "user_code": this.$User.user_code,
            "email": this.$User.email,
            "first_name": this.$User.first_name,
            "last_name": this.$User.last_name,
            "first_name_kana": this.$User.first_name_kana,
            "last_name_kana": this.$User.last_name_kana,
            "here_date": this.$User.here_date,
            "sex": this.$User.sex,
            "is_admin": 0,
            
		};
      user.update(post_json,this.$User.id)
        .then((response) => {
          if (response != null) {
            // this.group_list = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
			this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getUser() {
      user.show(this.$User.id)
        .then((response) => {
          if (response != null) {
            this.user = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
			this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getGroupList() {
      user.group_list()
        .then((response) => {
          if (response != null) {
            this.group_list = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
			this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getUserList() {
      user.list()
        .then((response) => {
          if (response != null) {
            this.user_list = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
			this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    postGroupList() {
      var post_json = {
				"name": "コントロール部",
				"description": "コントロール部の説明",
		};
      user.post_group(post_json)
        .then((response) => {
          if (response != null) {
            // this.group_list = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
			this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },

    postGroupUser() {
      var post_json = {
				"users":[
          {
            "user_id": "94b03245-184b-44bc-ac37-c5ddc002bb5b",
            "role": "",
            "here_date": "2021-09-01",
            "leaving_date": null,
          }
        ]
		};
      user.post_group_user(post_json,1)
        .then((response) => {
          if (response != null) {
            // this.group_list = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
			this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    posttag() {
      var post_json = {
            "name": "プライベート",
            
		};
      user.post_tag(post_json)
        .then((response) => {
          if (response != null) {
            // this.group_list = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
			this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },

    deletetag() {
      user.delete_tag('7')
        .then((response) => {
          if (response != null) {
            // this.group_list = response.data.data;
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
			this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    document.body.className = 'page_board-archive';
    $(function(){
        $('#js-loader').delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
